import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { baseUrl } from '../api';
import { Image, Table, Row, Attributes, InfoIcon, WarningIcon, List, Expander, Tabs } from '../components';
export const endPointUrl = [{
  param: 'GET',
  value: '/nodes/pth?children=1&vprop=1'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endPointUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Row mdxType="Row">
    <h1>Retrieve account information</h1>
    </Row>
    <br />
    <Row mdxType="Row">
	<div>
		<p>Upon completing the authorization steps, retrieving the account information is a helpful next step.</p>
        <p>
        Various SmartVault API endpoints reference using the account name within the URL endpoint.  For instance see the examples in <a href="/email-templates">Email templates</a>.
        <br />
        The `account_name` used for those endpoints can be retrieved using the endpoint documented here and is shown in the success response.
        <br />
        <br />
        The base URL is on the <a href="/api">Introduction</a> page.
    </p>
	</div>
	<Table title="Retrieve account information" rows={endPointUrl} mdxType="Table" />
    </Row>
    <br />
    <h2 {...{
      "id": "query-parameters"
    }}><strong parentName="h2">{`Query parameters`}</strong></h2>
    <hr></hr>
    <p>{`Query parameters to specify and control the amount of data returned in the response.`}</p>
    <Expander title="query parameters" mdxType="Expander">
	<Attributes items={[{
        name: 'children',
        type: 'number (optional)',
        description: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
      }, {
        name: 'direction',
        type: 'string (optional)',
        description: 'Sets the sorting direction. Can be either "asc" for ascending results or "desc" for descending. Default is "asc".',
        values: ['asc', 'desc']
      }, {
        name: 'eprop',
        type: 'string (optional)',
        description: 'Tells the server to return extended property information in the results. It may be set to "0" or "1". Default is "0".'
      }, {
        name: 'page',
        type: 'number (optional)',
        description: 'Sets the zero-based number of the result page to be returned. When combined with per_page and a sorting criteria, this can be used to paginate through larger result sets. Default is 0.'
      }, {
        name: 'per_page',
        type: 'number (optional)',
        description: 'Sets the maximum number of items to return in the query. Default is 50.'
      }, {
        name: 'sort',
        type: 'string (optional)',
        description: 'Sets the information for the results to be ordered. The value "relevance" is used for best-match sorting of search results. Default is "name".',
        values: ['name', 'modified_date', 'size', 'relevance']
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <h2 {...{
      "id": "response"
    }}>{`Response`}</h2>
    <hr />
    <Expander title="success response" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// /nodes/pth?children=1&vprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account", // <--- This is the 'account_name'
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                }
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br /><br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors"
      }}>{`error object`}</a>{` when request fails.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example request to retrieve account information
// https://rest.smartvault.com/nodes/pth?children=1&vprop=1
{
    "error": {
        "success": false,
        "error_code": -1593835519,
        "error_text": "Access Denied. Your rights may have changed; please Sign out and Sign back in to correct this problem.",
        "instance_guid": "96ecd165-2f79-4a82-8f89-96c81bb1a1d1",
        "status_code": 401
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <h3 {...{
      "id": "additional-response-examples"
    }}>{`Additional Response Examples`}</h3>
    <p>{`Here are some examples using various combinations of the optional query parameters.`}</p>
    <br />
    <List values={[{
      title: 'The "eprop" query parameter',
      value: 'Tells the server to return extended property information in the results. It may be set to "0" or "1". Default is "0".'
    }]} mdxType="List" />
    <br />
    <Expander title="eprop prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information without eprop
// /nodes/pth?children=1&vprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                }
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information with eprop
// /nodes/pth?children=1&vprop=1&eprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "accountExProperties": {
                    "available_user_licenses": 1,
                    "available_diskspace_bytes": 0,
                    "name": "SmartVault Account",
                    "features": [
                        {
                            "key": "FeatureContainers",
                            "value": true
                        },
                        {
                            "key": "FeatureCreateFolder",
                            "value": true
                        },
                        {
                            "key": "FeatureUserFolders",
                            "value": true
                        },
                        {
                            "key": "FeatureAccountingPackage",
                            "value": true
                        },
                        {
                            "key": "FeatureCustomBranding",
                            "value": true
                        },
                        {
                            "key": "FeatureViewActivityLog",
                            "value": true
                        },
                        {
                            "key": "FeatureFullTextSearch",
                            "value": true
                        },
                        {
                            "key": "FeatureNotifications",
                            "value": true
                        },
                        {
                            "key": "FeatureRecycleBin",
                            "value": true
                        },
                        {
                            "key": "FeatureViewVersionHistory",
                            "value": true
                        },
                        {
                            "key": "FeaturePreview",
                            "value": true
                        },
                        {
                            "key": "FeatureDmsMigration",
                            "value": false
                        },
                        {
                            "key": "FeatureFilesView",
                            "value": true
                        },
                        {
                            "key": "FeatureLacerteProseriesIntegration",
                            "value": false
                        },
                        {
                            "key": "FeatureAdvancedFolderTemplateEditor",
                            "value": true
                        },
                        {
                            "key": "FeatureAutoRefresh",
                            "value": true
                        },
                        {
                            "key": "FeatureEsignature",
                            "value": true
                        },
                        {
                            "key": "FeatureBilling",
                            "value": true
                        },
                        {
                            "key": "FeatureVirtualVault",
                            "value": false
                        },
                        {
                            "key": "FeatureCertifiedVault",
                            "value": false
                        },
                        {
                            "key": "FeatureESignatureSubscription",
                            "value": false
                        },
                        {
                            "key": "FeatureArchiveVault",
                            "value": false
                        },
                        {
                            "key": "FeatureGreenlist",
                            "value": false
                        },
                        {
                            "key": "FeatureRequestList",
                            "value": true
                        },
                        {
                            "key": "FeatureQuoters",
                            "value": true
                        }
                    ],
                    "available_seasonal_worker_licenses": 0,
                    "storage_location": "CLOUD",
                    "event_channel_name": "b3a3fde0-a8c7-41b0-828e-425245b51d8a-tUY0s-MVbE25zu-x7fNszw",
                    "pusher_app_key": "aaf278d31c92b4f43708",
                    "pusher_app_id": "137429",
                    "guest_license": false,
                    "locale": 1,
                    "niche": 2,
                    "questionnaire": false,
                    "developer_account": false,
                    "views": [
                        {
                            "view_type_account_id": "",
                            "view_type_id": "1",
                            "name": "Clients",
                            "hidden": false,
                            "display": "Clients"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "2",
                            "name": "SendToClients",
                            "hidden": true,
                            "display": "Send To Clients"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "3",
                            "name": "SendToClients.TaxReturns",
                            "hidden": true,
                            "display": "SendToClients - TaxReturns"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "4",
                            "name": "SendToClients.Organizers",
                            "hidden": true,
                            "display": "SendToClients - Organizers"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "5",
                            "name": "SendToClients.FinancialStatements",
                            "hidden": true,
                            "display": "SendToClients - FinancialStatements"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "7",
                            "name": "FilesAndFolders",
                            "hidden": false,
                            "display": "Files And Folders"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "8",
                            "name": "Employees",
                            "hidden": false,
                            "display": "Employees"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "9",
                            "name": "InternalDocuments",
                            "hidden": false,
                            "display": "Internal Documents"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "10",
                            "name": "PublicDocuments",
                            "hidden": true,
                            "display": "Public Documents"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "11",
                            "name": "ManageFolderTemplates",
                            "hidden": false,
                            "display": "Manage Folder Templates"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "12",
                            "name": "EmailTemplates",
                            "hidden": true,
                            "display": "Email Templates"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "13",
                            "name": "ActivityLog",
                            "hidden": false,
                            "display": "Activity Log"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "14",
                            "name": "Clients.SideNav",
                            "hidden": false,
                            "display": "Clients"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "15",
                            "name": "SendToClients.SideNav",
                            "hidden": true,
                            "display": "Send To Clients"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "17",
                            "name": "FilesAndFolders.SideNav",
                            "hidden": false,
                            "display": "Files And Folders"
                        },
                        {
                            "view_type_account_id": "",
                            "view_type_id": "18",
                            "name": "Employees.SideNav",
                            "hidden": false,
                            "display": "Employees"
                        }
                    ],
                    "state": "Trialing",
                    "selectors": [
                        {
                            "entity_definition": "SmartVault.Core.UserAssociation",
                            "template_name": "SmartVault.Core.UserTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Core.ManagedOrganization",
                            "template_name": "Managed Organization",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Accounting.Client",
                            "template_name": "SmartVault.Everyone.ClientTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Everyone.GeneralAnnualEngagement",
                            "template_name": "SmartVault.Everyone.GeneralAnnualTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Everyone.GeneralQuarterlyEngagement",
                            "template_name": "SmartVault.Everyone.GeneralQuarterlyTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Everyone.GeneralMonthlyEngagement",
                            "template_name": "SmartVault.Everyone.GeneralMonthlyTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        },
                        {
                            "entity_definition": "SmartVault.Core.ProjectEngagement",
                            "template_name": "SmartVault.Core.ProjectEngagementTemplate",
                            "template_version": -1,
                            "rule_type": "CONST",
                            "global": false
                        }
                    ],
                    "migration_control": [
                        {
                            "source": "dms.intuit.com",
                            "entities": [
                                {
                                    "applies_to": "SmartVault.Accounting.Client",
                                    "selectors": [
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/[^/]+$",
                                            "display_name": "Move root documents to miscellaneous (using association)",
                                            "match_to_association": "misc.tags.core.smartvault.com"
                                        },
                                        {
                                            "apply_to": "FOLDER",
                                            "expression": "^/Permanent Files?$",
                                            "match_to": "permanent_files",
                                            "display_name": "Map Permanent File or Permanent Files folder to permanent_files"
                                        }
                                    ]
                                },
                                {
                                    "applies_to": "SmartVault.Accounting.TaxEngagement",
                                    "selectors": [
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/[^/]+$",
                                            "display_name": "Move root documents to miscellaneous (using association)",
                                            "match_to_association": "misc.tags.core.smartvault.com"
                                        },
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/Tax Returns/.*Client Copy.*\\\\.pdf$",
                                            "display_name": "Move client copy of tax return to location of Client Copy (using association)",
                                            "match_to_association": "clientcopy.taxreturn.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/Tax Returns/.*Government filing copy.*\\\\.pdf$",
                                            "display_name": "Move Government copy of tax return to location of Government/File Copy (using association)",
                                            "match_to_association": "governmentcopy.taxreturn.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/Tax Returns/[0-9]{4} File Copy for.*\\\\.pdf$",
                                            "display_name": "Move filing copy of tax return to location of Government/File Copy (using association)",
                                            "match_to_association": "governmentcopy.taxreturn.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "DOCUMENT",
                                            "expression": "^/Tax Returns/[0-9]{4} Complete return.*\\\\.pdf$",
                                            "display_name": "Move complete copy of tax return to location of Complete Copy (using association)",
                                            "match_to_association": "complete.taxreturn.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "FOLDER",
                                            "expression": "^/Organizer$",
                                            "display_name": "Map Organizer folder to Firm Source Documents (using association)",
                                            "match_to_association": "firmtaxsource.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "FOLDER",
                                            "expression": "^/Source Documents$",
                                            "display_name": "Map Source Documents folder to Firm Source Documents (using association)",
                                            "match_to_association": "firmtaxsource.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "FOLDER",
                                            "expression": "^/Tax Plan Reports$",
                                            "display_name": "Map Tax Plan Reports to Next Year's Files (using association)",
                                            "match_to_association": "nextyearsfiles.tags.accounting.smartvault.com"
                                        },
                                        {
                                            "apply_to": "FOLDER",
                                            "expression": "^/Tax Returns$",
                                            "display_name": "Map Tax Returns to Prepare File Copy (using association)",
                                            "match_to_association": "preparerfilecopy.taxreturn.tags.accounting.smartvault.com"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                }
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br /><br />
    <List values={[{
      title: 'The "vprop" query parameter',
      value: 'Tells the server to return extended version information in the results. It may be set to "0" or "1". Default is "0".'
    }]} mdxType="List" />
    <br />
    <Expander title="vprop prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information with vprop
// /nodes/pth?children=1&vprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                }
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information without vprop
// /nodes/pth?children=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType"
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br /><br />
    <List values={[{
      title: 'The "children" query parameter',
      value: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
    }]} mdxType="List" />
    <br />
    <Expander title="children prop difference" mdxType="Expander">
      <Row codeExamples mdxType="Row">
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information with children=1
// /nodes/pth?children=1&vprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                }
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`// Example request to retrieve account information with children=2
// /nodes/pth?children=2&vprop=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "My Accounts",
        "createdOn": "2022-06-28T12:22:55-05:00",
        "modifiedOn": "2022-06-28T12:22:55-05:00",
        "uri": "/nodes/pth",
        "dav_uri": "/nodes/pth",
        "your_permissions": 68,
        "nodeType": "RootNodeType",
        "total_children": 1,
        "children": [
            {
                "name": "SmartVault Account",
                "createdOn": "2022-06-28T11:22:55-06:00",
                "modifiedOn": "2022-06-28T11:22:55-06:00",
                "uri": "/nodes/pth/SmartVault Account",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 22579317,
                "nodeType": "AccountNodeType",
                "total_children": 3,
                "children": [
                    {
                        "name": "Clients",
                        "createdOn": "2022-07-18T09:09:10-05:00",
                        "modifiedOn": "2022-07-18T09:09:10-05:00",
                        "uri": "/nodes/pth/SmartVault Account/Clients",
                        "dav_uri": "/nodes/pth/SmartVault Account/Clients",
                        "your_permissions": 1048689,
                        "nodeType": "ContainerNodeType",
                        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Container&id=ZVYdBMaoP0mSXxtU4lUZEw",
                        "versionExProperties": {
                            "uuid": "ZVYdBMaoP0mSXxtU4lUZEw",
                            "version": 0,
                            "type": "Container",
                            "parent_uuid": "tUY0s-MVbE25zu-x7fNszw",
                            "parent_type": "Container"
                        }
                    },
                    {
                        "name": "Employees",
                        "createdOn": "2022-07-18T09:09:10-05:00",
                        "modifiedOn": "2022-07-18T09:09:10-05:00",
                        "uri": "/nodes/pth/SmartVault Account/Employees",
                        "dav_uri": "/nodes/pth/SmartVault Account/Employees",
                        "your_permissions": 1048693,
                        "nodeType": "ContainerNodeType",
                        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Container&id=o0Uid0xrFkOw4Xw6THvvYQ",
                        "versionExProperties": {
                            "uuid": "o0Uid0xrFkOw4Xw6THvvYQ",
                            "version": 0,
                            "type": "Container",
                            "parent_uuid": "tUY0s-MVbE25zu-x7fNszw",
                            "parent_type": "Container"
                        }
                    },
                    {
                        "name": "Firm",
                        "createdOn": "2022-07-18T09:09:10-05:00",
                        "modifiedOn": "2022-07-18T09:09:10-05:00",
                        "uri": "/nodes/pth/SmartVault Account/Firm",
                        "dav_uri": "/nodes/pth/SmartVault Account/Firm",
                        "your_permissions": 1048693,
                        "nodeType": "ContainerNodeType",
                        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Container&id=_CTTx0GpYUCwu2kQF-O1Hw",
                        "versionExProperties": {
                            "uuid": "_CTTx0GpYUCwu2kQF-O1Hw",
                            "version": 0,
                            "type": "Container",
                            "parent_uuid": "tUY0s-MVbE25zu-x7fNszw",
                            "parent_type": "Container"
                        }
                    }
                ],
                "versionExProperties": {
                    "uuid": "tUY0s-MVbE25zu-x7fNszw",
                    "version": 3,
                    "type": "OrganizationalUnit",
                    "parent_uuid": "Wrwl5edDwEGTLC9H0fUUGg",
                    "parent_type": "RootObject"
                },
                "total_files": 0,
                "total_folders": 0,
                "total_vaults": 0,
                "total_containers": 3
            }
        ],
        "total_files": 0,
        "total_folders": 0,
        "total_vaults": 0,
        "total_containers": 0
    }
}
`}</code></pre>
      </Row>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      